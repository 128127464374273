exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-contact-us-tsx": () => import("./../../../src/pages/Contact/Contact-Us.tsx" /* webpackChunkName: "component---src-pages-contact-contact-us-tsx" */),
  "component---src-pages-darling-details-about-darlings-tsx": () => import("./../../../src/pages/Darling-Details/About-Darlings.tsx" /* webpackChunkName: "component---src-pages-darling-details-about-darlings-tsx" */),
  "component---src-pages-darling-details-cookies-tsx": () => import("./../../../src/pages/Darling-Details/Cookies.tsx" /* webpackChunkName: "component---src-pages-darling-details-cookies-tsx" */),
  "component---src-pages-darling-details-health-and-safety-tsx": () => import("./../../../src/pages/Darling-Details/Health-and-Safety.tsx" /* webpackChunkName: "component---src-pages-darling-details-health-and-safety-tsx" */),
  "component---src-pages-darling-details-how-we-work-tsx": () => import("./../../../src/pages/Darling-Details/How-We-Work.tsx" /* webpackChunkName: "component---src-pages-darling-details-how-we-work-tsx" */),
  "component---src-pages-darling-details-our-guarantee-tsx": () => import("./../../../src/pages/Darling-Details/Our-Guarantee.tsx" /* webpackChunkName: "component---src-pages-darling-details-our-guarantee-tsx" */),
  "component---src-pages-darling-details-privacy-policy-tsx": () => import("./../../../src/pages/Darling-Details/Privacy-Policy.tsx" /* webpackChunkName: "component---src-pages-darling-details-privacy-policy-tsx" */),
  "component---src-pages-darling-details-security-and-safety-tsx": () => import("./../../../src/pages/Darling-Details/Security-and-Safety.tsx" /* webpackChunkName: "component---src-pages-darling-details-security-and-safety-tsx" */),
  "component---src-pages-darling-details-terms-and-conditions-tsx": () => import("./../../../src/pages/Darling-Details/Terms-and-Conditions.tsx" /* webpackChunkName: "component---src-pages-darling-details-terms-and-conditions-tsx" */),
  "component---src-pages-darling-details-the-darlings-tsx": () => import("./../../../src/pages/Darling-Details/The-Darlings.tsx" /* webpackChunkName: "component---src-pages-darling-details-the-darlings-tsx" */),
  "component---src-pages-darling-details-why-choose-darlings-tsx": () => import("./../../../src/pages/Darling-Details/Why-Choose-Darlings.tsx" /* webpackChunkName: "component---src-pages-darling-details-why-choose-darlings-tsx" */),
  "component---src-pages-darling-services-bed-changing-tsx": () => import("./../../../src/pages/Darling-Services/Bed-Changing.tsx" /* webpackChunkName: "component---src-pages-darling-services-bed-changing-tsx" */),
  "component---src-pages-darling-services-freezer-defrosting-tsx": () => import("./../../../src/pages/Darling-Services/Freezer-Defrosting.tsx" /* webpackChunkName: "component---src-pages-darling-services-freezer-defrosting-tsx" */),
  "component---src-pages-darling-services-fridge-cleaning-tsx": () => import("./../../../src/pages/Darling-Services/Fridge-Cleaning.tsx" /* webpackChunkName: "component---src-pages-darling-services-fridge-cleaning-tsx" */),
  "component---src-pages-darling-services-inside-window-cleaning-tsx": () => import("./../../../src/pages/Darling-Services/Inside-Window-Cleaning.tsx" /* webpackChunkName: "component---src-pages-darling-services-inside-window-cleaning-tsx" */),
  "component---src-pages-darling-services-one-off-cleaning-tsx": () => import("./../../../src/pages/Darling-Services/One-Off-Cleaning.tsx" /* webpackChunkName: "component---src-pages-darling-services-one-off-cleaning-tsx" */),
  "component---src-pages-darling-services-regular-cleaning-tsx": () => import("./../../../src/pages/Darling-Services/Regular-Cleaning.tsx" /* webpackChunkName: "component---src-pages-darling-services-regular-cleaning-tsx" */),
  "component---src-pages-darling-services-spring-cleaning-tsx": () => import("./../../../src/pages/Darling-Services/Spring-Cleaning.tsx" /* webpackChunkName: "component---src-pages-darling-services-spring-cleaning-tsx" */),
  "component---src-pages-darling-services-washing-and-ironing-tsx": () => import("./../../../src/pages/Darling-Services/Washing-and-Ironing.tsx" /* webpackChunkName: "component---src-pages-darling-services-washing-and-ironing-tsx" */),
  "component---src-pages-fa-qs-tsx": () => import("./../../../src/pages/FAQs.tsx" /* webpackChunkName: "component---src-pages-fa-qs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-latest-news-tsx": () => import("./../../../src/pages/News/Latest-News.tsx" /* webpackChunkName: "component---src-pages-news-latest-news-tsx" */),
  "component---src-pages-news-year-2014-tsx": () => import("./../../../src/pages/News/Year-2014.tsx" /* webpackChunkName: "component---src-pages-news-year-2014-tsx" */),
  "component---src-pages-testmonials-tsx": () => import("./../../../src/pages/Testmonials.tsx" /* webpackChunkName: "component---src-pages-testmonials-tsx" */),
  "component---src-pages-workfor-us-tsx": () => import("./../../../src/pages/WorkforUs.tsx" /* webpackChunkName: "component---src-pages-workfor-us-tsx" */)
}

